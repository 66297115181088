import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de que estás importando Link

const HeroSection = () => {
  return (
    <section className="relative bg-[url('../src/assets/high-angle-desk-arrangement-with-laptop.jpg')] bg-cover bg-center bg-no-repeat mt-1 z-0">
      <div className="absolute inset-0 bg-gray-900/85 lg:bg-gray-900/80"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 py-20 md:py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
        <div className="max-w-xl text-center lg:text-left">
          <h1 className="text-2xl sm:text-3xl lg:text-5xl font-extrabold text-white">
            Un community manager para potenciar tu{' '}
            <strong className="block font-extrabold text-secondary">presencia online.</strong>
          </h1>

          <p className="mt-4 max-w-lg text-white sm:text-lg">
            Creamos sitios web efectivos y gestionamos tus redes sociales, ofreciendo servicios adicionales como community managers para optimizar tu presencia online.
          </p>

          <div className="mt-8 flex flex-wrap gap-4 justify-center lg:justify-start">
            {/* Cambié a Link en lugar de "a" para asegurar que use React Router */}
            <Link
              to="/contacto"
              className="block w-full lg:w-auto rounded bg-primary px-8 py-3 text-lg text-white shadow hover:bg-secondary focus:outline-none focus:ring active:bg-secondary font-bold"
            >
              Contacta
            </Link>

            <Link
              to="/nosotros" 
              className="block w-full lg:w-auto rounded bg-white px-8 py-3 text-lg font-medium text-primary shadow hover:text-secondary focus:outline-none focus:ring active:text-secondary"
            >
              Más sobre nosotros
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
