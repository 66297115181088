import React from 'react';
import ctaImage from '../../assets/pexels-wdnet-230544.jpg'; 
import { Link } from 'react-router-dom';

const CtaSection = () => {
  return (
    <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2">
      <div className="p-8 md:p-12 lg:px-16 lg:py-24">
        <div className="mx-auto max-w-xl text-center sm:text-left">
          <h2 className="text-3xl font-bold text-gray-900 md:text-4xl">
            Impulsa tu negocio con nosotros
          </h2>
          <p className="hidden text-gray-500 md:mt-4 md:block">
            Descubre el poder de las soluciones digitales estratégicas diseñadas para mejorar el alcance e impacto de tu marca. Nos especializamos en crear contenido convincente y experiencias de usuario perfectas que impulsan el crecimiento y el compromiso.
          </p>
          <div className="mt-4 md:mt-8">
            <Link
              to="/contacto"
              className="inline-block w-full lg:w-auto rounded bg-primary px-8 py-3 text-lg text-white shadow hover:bg-secondary focus:outline-none focus:ring active:bg-secondary font-bold"
            >
              Comienza hoy
            </Link>
          </div>
        </div>
      </div>
      <img
        alt="Imagen de CTA"
        src={ctaImage} 
        className="h-72 w-full object-cover sm:h-full"
      />
    </section>
  );
};

export default CtaSection;
